import React from 'react';

import DalabGrid from './components/DalabGrid';
import DalabCard from './components/DalabCard';

export const news = [
  {
    date: '03.12.2024',
    title: 'ETH Medal for outstanding doctoral thesis for Antonio Orvieto',
    image: 'images/people/AntonioOrvieto/photo.jpg',
    markdown: `
We are delighted to announce that Antotio Orvieto has received an ETH Medal for his outstanding dissertation. Congratulations Antonio! See details [here](https://inf.ethz.ch/news-and-events/spotlights/infk-news-channel/2024/12/outstanding-doctoral-theses-2024.html).
`
  },
  {
    date: '18.11.2024',
    title: 'Google PhD Fellowship for Lorenzo Noci',
    image: 'images/people/LorenzoNoci/photo.jpg',
    markdown: `
We are delighted to announce that our PhD student Lorenzo Noci has been awarded the prestigious Google PhD Fellowship! This remarkable achievement acknowledges his work on advancing the field of deep learning. Congratulations, Lorenzo! See details [here](https://inf.ethz.ch/news-and-events/spotlights/infk-news-channel/2024/10/google-phd-fellowships-2024.html).
`
  },
  {
    date: '19.12.2022',
    title: 'Information Theory: A Short Course',
    image: 'images/news/images/info-theory.png',
    markdown: `
This short course on information theory for Bachelor students will introduce fundamental concepts such as entropy, information, sufficiency, typicality, concentration and will present a range of topics from data coding, statistics, inference, decision-making and learning that relate in interesting ways to information theory. It follows the 80-20 rule, trying to focus on relevance and intellectual creativity and leaving out some of the more specialized and technically advanced results. It can be found [here](https://www.vorlesungen.ethz.ch/Vorlesungsverzeichnis/lerneinheit.view?lerneinheitId=171419&semkez=2023S&lang=en) in the course catalog.
`
  }, {
    date: '19.12.2022',
    title: 'Carsten Eickhoff joins the Faculty at the University of Tübingen',
    image: 'images/news/images/carsten_eickhoff.png',
    markdown: `
Carsten Eickhoff, who was a postdoc at DA lab from 2014 - 2017 and one of the founding lab members, has taken up a tenured faculty position at the University of Tübingen as Professor of Medical Data Science. Carsten’s research has been in artificial intelligence for improving patient safety, individual health and quality of medical care. In Tübingen, he will lead the Health NLP Laboratory, specializing in mining, representation and retrieval of large-scale natural language resources. He will remain a visiting researcher at Brown University
`
  }, {
    date: '8.11.2019',
    title: 'ETH Medal and Fritz Kutter Award for Celestine Mendler-Dünner',
    image: 'images/news/images/celestine.jpg',
    markdown: `
We are very pleased to announce that our former PHD student Celestine Mendler-Dünner won the [Fritz Kutter Award](http://www.ckutter.ch) for outstanding research with industrial impact and the [ETH Medal](https://inf.ethz.ch/news-and-events/spotlights/2019/11/ETHmedalDoctoral.html) for her outstanding doctoral thesis on "System-Aware Algorithms for Machine Learning". Congratulations to Celestine!
`
  },
  {
    date: '18.09.2019',
    title: 'Artificial intelligence probes dark matter in the universe',
    image: 'images/news/images/janis_eth.jpg',
    markdown: `
Our team as well as the cosmology group at ETH have developed a new approach to the problem of dark matter and dark energy in the universe. Our findinds are described [here](https://ethz.ch/en/news-and-events/eth-news/news/2019/09/artificial-intelligence-probes-dark-matter-in-the-universe.html).
`
  },
  {
    date: '09.06.2019',
    title: 'AiCosmo workshop',
    image: 'images/news/images/cosmic_web_ascona.jpg',
    markdown: `
We organized an AI and cosmology workshop aiming at developing the interactions between both fields. The workshop was organized at the amazing site of Monte Verita in Switzerland and had an amazing collection of speakers, see details [here](https://sites.google.com/site/aicosmo2019/).
`
  },
  {
    date: '06.02.2019',
    title: 'AI challenges for companies',
    image: 'images/news/images/bigdatainsider.jpg',
    markdown: `
Are certain business models more suitable for AI companies and projects than others? Thomas Hofmann answers this topic and other related questions in his interview with [Big Data Insider](https://www.bigdata-insider.de/leitfaden-fuer-fuehrungskraefte-zu-ki-und-maschinellem-lernen-a-773606/).
`
  },
  {
    date: '29.01.2019',
    title: 'ELLIS Society Founded',
    image: 'images/news/images/ellis.jpg',
    markdown: `
Top European Machine Learning Scientists join forces to establish European Excellence Network for Public Artificial Intelligence Research. More information on the initiative termed European Laboratory for Learning and Intelligent Systems (ELLIS) can be found [here](https://ellis.eu/en/news/ellis-society-founded).
`
  },
  {
    date: '20.01.2019',
    title: 'Thomas Hofmann co-authored for an official statement of the German National Academy of Sciences',
    image: 'images/news/images/logo_leopoldina.png',
    markdown: `
Thomas Hofmann contributed to the paper ["Privatheit in Zeiten der Digitalisierung"](https://www.leopoldina.org/uploads/tx_leopublication/2018_Stellungnahme_BigData.pdf) published by the German National Academy of Sciences (publication in German only).
`
  },
  {
    date: '11.01.2019',
    title: 'Template Pictorial and Textual Representations Project funded by SNF ',
    image: 'images/news/images/snf.png',
    markdown: `
We are pleased to announce that our research proposal "Deep Learning for Generating Template Pictorial and Textual Representations" was approved by the Swiss National Science Foundation (SNF). The project concerns research on the design of novel algorithms that jointly process natural language and visual data focusing on deep learning methods. This is a joint project with the Language Intelligence and Information Retrieval research lab at KU Leuven. In this regard we warmly welcome our new team member [Dario Pavllo]( http://da.inf.ethz.ch/people/DarioPavllo/), who will dedicate himself to this project.
`
  },
  {
    date: '22.11.2017',
    title: 'SRF Radio Interview with Thomas Hofmann ',
    image: 'images/news/images/srf.jpg',
    markdown: `
The National Swiss Radio (SRF 1) conducted an interview with Thomas Hofmann on Artificial Intelligence. The interview is available in German [here](https://www.srf.ch/play/radio/trend/audio/wie-computer-lernen-e-mails-zu-sortieren?id=e3c7ff60-85a1-437e-bf3c-3790a0a8159c&startTime=0.82&startTime=671&station=69e8ac16-4327-4af4-b873-fd5cd6e895a7) (starting after 17 mins).
`
  },
  {
    date: '1.11.2017',
    title: 'NZZ Interview with Thomas Hofmann ',
    image: 'images/news/images/th.jpg',
    markdown: `
The Neue Zürcher Zeitung conducted an interview with Thomas Hofmann on Artificial Intelligence and its implications. The arcticle is available in German "[Oft Enscheiden Menschen sehr schlecht](http://www.nzz.ch/wirtschaft/oft-entscheiden-menschen-sehr-schlecht-ld.1325428) ".
`
  },
  {
    date: '1.11.2017',
    title: 'Digital Shapers 2017!',
    image: 'images/news/images/digitalshapers2017.png',
    markdown: `
Thomas Hofmann has been listed as a top 100 [Digital Shaper in Switzerland 2017](http://www.netzwoche.ch/news/2017-08-25/das-sind-die-100-digital-shapers-2017)
`
  },
  {
    date: '1.11.2017',
    title: 'A.I., Genie in the Bottle?',
    image: 'images/news/images/institute_logo_lake.png',
    markdown: `
Thomas Hofmann has presented a talk on " A.I., Genie in the Bottle?" at the SwissRe ETH Risk Center [Workshop on Autonomous Decision Making](http://institute.swissre.com/events/Autonomous_Decision_Making.html).
`
  },
  {
    date: '1.11.2017',
    title: 'ACM SIGIR Test of Time Award',
    image: 'images/news/images/sigir.png',
    markdown: `
Thomas Hofmann’s 1999 paper "Probabilistic Latent Semantic Indexing" has been awarded the test of time award by ACM SIGIR, see [here](http://sigir.org/awards/test-of-time-awards/pre-2002-recipients). It has received more than 5000 citations up to date.
`
  },
  {
    date: '19.10.2017',
    title: 'Collaboration with CSEM',
    image: 'images/news/images/csem.png',
    markdown: `
The Institute for Machine Learning at ETH Zurich is partnering with [CSEM](https://www.csem.ch/home) for exploring exciting cutting edge research for modelling temporal processes. This collaboration will lead to the joint supervision of a PhD student starting in the fall 2017.
`
  },
  {
    date: '10.10.2017',
    title: 'Deep Learning for Observational Cosmology funded by SDSC',
    image: 'images/news/images/cosmic_web.jpg',
    markdown: `
We are pleased to announce that our research proposal "Deep Learning for Observational Cosmology" was accepted by the [Swiss Data Science Center (SDSC)](https://datascience.ch/). This interdisciplinary project is a joint collobration between the Data Analytics Lab and the [Cosmology group at ETH](http://www.cosmology.ethz.ch/) led by Prof. Alexandre Refregier. We will address some of the most important problems in the field of Observational Cosmology by capitalising on the latest developments in the field of deep neural networks.
`
  },
  {
    date: '15.10.2017',
    title: 'AMiner Influential Scholars',
    image: 'images/news/images/AMiner.png',
    markdown: `
Thomas Hofmann has been recognized on the [AMiner most influntial scholar award list of 2016](http://aminer.org/mostinfluentialscholar) for his contributions to Machine Learning and Information Retrieval.
`
  },
  {
    date: '16.11.2016',
    title: 'Conversational Agents Project funded by SNF',
    image: 'images/news/images/snf.png',
    markdown: `
Our SNF research proposal „Conversational Agents for Interactive Communication“ was approved for 36 months funding. The project will combine many areas of Machine Learning and Natural Language Processing to a system tackling the challenge of natural understanding.
`
  },
  {
    date: '29.08.2016',
    title: 'Facebook Research GPU Partnership Program',
    image: 'images/news/images/fairbanner.jpg',
    markdown: `
Facebook AI Research selected the Data Analytics Lab to be part of their GPU Partnership Program. They will be distributing 22 high-powered GPU servers to 15 research groups across 9 European countries. Further details are available [here](https://research.facebook.com/blog/facebook-to-accelerate-global-ai-research-with-new-gpu-program-recipients/).
`
  },
  {
    date: '11.08.2016',
    title: 'Joint collaboration with Microsoft Research',
    image: 'images/news/images/ms_logo_cam.gif',
    markdown: `
We are pleased to announce that our group has been awarded funding for a research project with [Microsoft Research](https://www.microsoft.com/en-us/research/lab/microsoft-research-cambridge/). Our research project will focus on the development of generative probabilistic graphical models for modelling complex data such as text and images.
`
  },
  {
    date: '10.08.2016',
    title: 'Carsten Eickhoff speaking at Swiss eHealth Summit',
    image: 'images/news/images/ehs.png',
    markdown: `
Carsten will present the Data Analytics Lab's line of work dedicated to patient-centric natural language processing and information retrieval techniques in clinical settings at the [Swiss eHealth Summit 2016](http://www.ehealthsummit.ch/ehome/index.php?eventid=169404&).
`
  },
  {
    date: '02.08.2016',
    title: 'Rolf Jagerman speaking at Spark Summit Europe',
    image: 'images/news/images/glint.png',
    markdown: `
As one of the invited speakers to this year's Spark Summit Europe, Rolf Jagerman will present [Glint: An Asynchronous Parameter Server for Spark](https://spark-summit.org/eu-2016/events/glint-an-asynchronous-parameter-server-for-spark/). The system was designed in the course of Rolf's MSc. thesis project in the Data Analytics Lab.
`
  },
  {
    date: '18.05.2016',
    title: 'And the Qualcomm Fellowship 2016 goes to...',
    image: 'images/news/images/qualcomm.png',
    markdown: `
We are very pleased to announce that our PhD student Jason Lee was awarded a [Qualcomm Fellowship 2016](https://www.qualcomm.com/invention/research/university-relations/innovation-fellowship/2016-europe)! Jason joined the lab in November 2015 and currently works on a unified neural language model for morphology grammar and coherence. Each winner will be awarded a 40,000 USD fellowship and receive mentorship from Qualcomm engineers.
`
  },
  {
    date: '26.02.2016',
    title: 'Best Text Sentiment Analysis',
    image: 'images/news/images/smiley-jar.jpg',
    markdown: `
Our lab's master students Jan Deriu and Maurice Gonzenbach won the 2016 [text sentiment classification competition](https://www.inf.ethz.ch/news-and-events/spotlights/semeval2016.html). The yearly international benchmark is organized by [SemEval](https://en.wikipedia.org/wiki/SemEval), and consists in analyzing large amounts of tweets. We placed first out of 34 teams from 24 countries. Maurice and Jan are supervized by Fatih Uzdilli, Valeria De Luca, Aurelien Lucchi and Martin Jaggi.
`
  },
  {
    date: '11.08.2015',
    title: 'ACM SIGIR Best Paper',
    markdown: `
Carsten Eickhoff, Sebastian Dungs and Vu Tran receive an ACM SIGIR Best Paper Award honorable mention for their paper ['An Eye-tracking Study of Query Reformulation'](http://dl.acm.org/citation.cfm?id=2766462.2767703).
`
  },
  {
    date: '01.04.2015',
    title: 'GamifIR 2015 Best Presentation Award',
    image: 'images/news/images/Eickhoff_bestpresentationaward.png',
    markdown: `
Carsten Eickhoff receives along with Ragnhild Eg the GamifIR 'Best Presentation Award' at the [ 36th European Conference on Information Retrieval](http://ecir2015.ifs.tuwien.ac.at/wp/) in Vienna, Austria.
`
  },
  {
    date: '29.03.2015',
    title: 'Keynote at GamifIR 2015',
    image: 'images/news/images/ecir-2015.jpg',
    markdown: `
Carsten Eickhoff will give the keynote address at the [Workshop on Gamification for Information Retrieval](http://gamifir.dai-labor.de/) at ECIR in Vienna, Austria.
`
  },
  {
    date: '29.01.2015',
    title: 'Vom Monopol auf Daten ist abzuraten',
    image: 'images/news/images/BigData.png',
    markdown: `
Big Data ist Big Business. Die Sammlung und die Verknüpfung von Informationen über Menschen im Netz bringen Milliarden ein. Und die Methoden werden immer abgefeimter und perfekter. Es ist Zeit, dagegen vorzugehen. [Ein Artikel von Prof. Thomas Hofmann und Prof. Bernhard Schölkopf.](http://www.inf.ethz.ch/content/dam/ethz/special-interest/infk/department/Images%20and%20Content/News/In_the_media/2015-01-29%20FAZ%20Thomas%20Hofmann.pdf)
`
  },
  {
    date: '03.12.2014',
    title: 'Workshop in Montreal',
    markdown: `
We're co-organizing this year's [Optimization for Machine Learning workshop](http://www.opt-ml.org/) at NIPS in Montréal, Canada.
`
  },
  {
    date: '01.10.2014',
    title: 'Frank-Wolfe algorithm',
    image: 'images/news/images/frank-wolfe-optima.jpg',
    markdown: `
The Frank-Wolfe algorithm makes the cover story of the current issue of the [Newsletter of the Mathematical Optimization Society ](http://www.mathopt.org/Optima-Issues/optima95.pdf) .
`
  },
  {
    date: '23.09.2014',
    title: 'Introductory lecture',
    image: 'images/news/images/introductory-lecture.jpg',
    markdown: `
Since April 2014 Thomas Hofmann is professor in the Department of Computer Science. On Tuesday, 23 September he held his [introductory lecture (only available in German)](http://www.multimedia.ethz.ch/speakers/lecture).
`
  },
  {
    date: '01.09.2014',
    title: 'CoCoA accepted at NIPS',
    image: 'images/news/images/cocoa.jpg',
    markdown: `
[CoCoA - our framework for communication efficient distributed training of machine learning models](http://papers.nips.cc/paper/5599-communication-efficient-distributed-dual-coordinate-ascent) - was accepted at this year's NIPS conference.
`
  },
  {
    date: '19.08.2014',
    title: 'Google research proposal approved',
    markdown: `
The Google research proposal 'Bootstrapping Distributional Semantics from the Web' was just approved for 12 months funding. For further information on the google award programs, please visit [Research at Google](http://research.google.com/university/relations/).
`
  },
  {
    date: '11.08.2014',
    title: 'Paper accepted at ACM CIKM',
    markdown: `
The paper 'Modelling Complex Relevance Spaces with Copulas' by Carsten Eickhoff and Arjen de Vries was accepted at the [ACM Conference on Information and Knowledge Management (CIKM)](http://cikm2014.fudan.edu.cn/) in Shanghai, China.
`
  },
  {
    date: '11.08.2014',
    title: 'Paper accepted at IIiX',
    markdown: `
The paper 'Interactive Summarization of Social Media Streams' by Wen Li, Carsten Eickhoff and Arjen de Vries was just accepted as a demo at the [Information & Interaction in Context Conference (IIiX)](http://iiix2014.ur.de/).
`
  },
  {
    date: '16. - 20.06.2014',
    title: 'ICML 2014 Tutorial: Frank-Wolfe and Greedy Optimization for Learning with Big Data',
    image: 'images/news/images/frank-wolfe.jpg',
    markdown: `
Frank-Wolfe and Greedy Optimization methods have been successfully applied to a wide range of machine learning and signal processing applications, such as matrix factorization, multi-task learning, image denoising, boosting, metric learning and structured prediction. All these algorithms have in common that they rely on the atomic decomposition of the variable of interest, that is expanding it as a linear combination of the elements of a dictionary. In this tutorial, we present these algorithms in a unified framework.
`
  },
  {
    date: '16. - 20.06.2014',
    title: 'Research Network on Learning Systems: Summer School, Zurich, 16 - 20 June 2014',
    image: 'images/news/images/intelligent_systems.jpg',
    markdown: `
The Max-Planck-Institute for Intelligent Systems and the Eidgenössische Technische Hochschule (ETH) Zurich launched a research network in the research field of learning systems in August 2013. The network enables scientists from the Max-Planck-Institute for Intelligent Systems, with sites in Stuttgart and Tübingen, to collaborate with their Swiss colleagues, and vice versa.
`
  },
  {
    date: '09.05.2014',
    title: 'Critical Data: Measuring Society',
    image: 'images/news/images/konstanz.jpg',
    markdown: `
Massive advances in information and communication technology do not only gradually affect our society but cause a fundamental transition.
`
  },
  {
    date: '15.04.2014',
    title: 'ECIR 2014 Best Reviewer Award',
    image: 'images/news/images/ecir14.jpg',
    markdown: `
The [36th European Conference on Information Retrieval](http://ecir2014.org/) introduced the 'Best Reviewer Award' in recognition of those reviewers who delivered thorough reviews of highest quality and fairness containing actionable recommendations to the authors. This year, alongside 3 other PC members, Carsten Eickhoff received the award.
    `
  },
  {
    date: '13.04.2014',
    title: 'Crowd-Powered Experts',
    image: 'images/news/images/biopsy.jpg',
    markdown: `
Crowdsourcing is often applied for the task of replacing the scarce or expensive labour of experts with that of untrained workers. In this paper, we argue, that this objective might not always be desirable, but that we should instead aim at leveraging the considerable work force of the crowd in order to support the highly trained expert. Here, we demonstrate this different paradigm on the example of detecting malignant breast cancer in medical images.
    `
  }
];


export const NewsCard = newsItem =>
  <DalabCard
    title={newsItem.title}
    subheader={newsItem.date}
    image={newsItem.image}
    markdown={newsItem.markdown}
    url={newsItem.url} />

export default () =>
  <DalabGrid data={news} card={NewsCard} />
