import React from 'react';

import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';

import DalabGrid from './components/DalabGrid';

import { news, NewsCard } from './News';
import { semesters, LectureCard } from './Teaching';
import { publications, PublicationCard } from './Publications';


export default function Home() {
  return (
    <>
      <Box mt={2} mx={2} mb={2}>
        <Typography align='justify'>
          Welcome to the Data Analytics Lab, part of the <a href="https://inf.ethz.ch">Department of Computer Science</a> . The research focus of our lab is on modern machine learning models, in particular deep learning, which is at the core of many recent successes in data science and artificial intelligence. More specifically, we work on the following research topics:
          {/* Make alist of bold items every one of them in a new line*/}
          <List dense={true}>
            <ListItem>
              <ListItemText><b> (1) Theory of deep learning. </b></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText><b> (2) Machine learning inspired by natural intelligence and neurobiology. </b></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText><b> (3) Deep learning for the sciences, with an emphasis on astrophysics. </b></ListItemText>
            </ListItem>
          </List>

        </Typography>
      </Box>
      <Box mt={2} mx={2} mb={2}>
        <Typography variant='h5'>
          Featured Publications
        </Typography>
        <DalabGrid data={publications.filter(p => p.featured)} card={PublicationCard} />
      </Box>
      <Box mt={2} mx={2} mb={2}>
        <Typography variant='h5'>
          Current Lectures
        </Typography>
        <DalabGrid data={semesters[0].lectures} card={LectureCard} />
      </Box>
      <Box mt={2} mx={2} mb={2}>
        <Typography variant='h5'>
          Latest News
        </Typography>
        <DalabGrid data={news.slice(0, 2)} card={NewsCard} />
      </Box>
    </>
  );
}
